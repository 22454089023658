import styled from "styled-components"

import { hex2rgba } from "./tools"

export const Step = styled.div`
  width: 18rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem 1rem;

  @media (max-width: 640px) {
    margin: 0 0 1rem;
    width: 100%;
    max-width: 100%;
  }
`
export const StepWrapper = styled.div`
  position: relative;
`
export const Panel = styled.div`
  position: relative;
  margin: 0 0 0.5rem;
  width: 100%;
`

export const PanelContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent === "bottom" ? "flex-end" : "center"};
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3.5rem;
  width: 100%;
  min-height: 20rem;

  background-color: ${props => props.theme.color.navigationBg};
  box-shadow: 0 2px 8px 4px ${props => hex2rgba(props.theme.color.primary, 0.1)};
  border-radius: 1rem;

  svg {
    display: block;

    @media (max-width: 400px) {
      max-width: 100%;
    }
  }
`

export const Label = styled.div`
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.5rem 0.7rem;
  width: 100%;

  text-align: center;
  color: ${props => props.theme.color.whiteBg};
  font-family: ${props => props.theme.font.heading};
  text-transform: uppercase;
  font-size: 1.8rem;

  border-radius: 1rem 1rem 0 0;
  background-color: ${props => props.theme.color.primary};
  transition: all 0.2s ease-in-out;
`
