import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"
import useWindowDimensions from "../../../components/useWindowDimensions"
import styled from "styled-components"

import { gsap } from "gsap"
// import { GSDevTools } from "gsap/dist/GSDevTools"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"

import {
  StepWrapper,
  Step,
  Panel,
  PanelContent,
  Label,
} from "../../../styles/how-it-works"
import { useTranslateValue } from "../../../data/TranslateContext"

const PeelCup = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);

  width: 210px;

  user-select: none;
`

export default function Peel() {
  const images = useStaticQuery(graphql`
    query peelStepQuery {
      cup: file(relativePath: { eq: "how-it-works/peel-cup.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { width } = useWindowDimensions()

  const [triggerRef, inView] = useInView({
    threshold: 1,
  })

  useEffect(() => {
    const speed = width >= 768 ? 500 : 0
    const timer = setTimeout(() => {
      if (inView) {
        clickFeature()
      }
    }, speed)

    return () => clearTimeout(timer)
  }, [inView]) // eslint-disable-line react-hooks/exhaustive-deps

  const [featureTimeline] = useState(gsap.timeline({ paused: true }))

  const actionLinesPaths = [
    {
      d:
        "M32.0472821,78.9538177 L29.9064468,78.0469278 C29.3152714,77.796497 28.6330149,78.0727251 28.3825842,78.6639004 C28.1321534,79.2550758 28.4083815,79.9373323 28.9995568,80.187763 L31.1403921,81.094653 C31.7315675,81.3450837 32.413824,81.0688557 32.6642547,80.4776803 C32.9146855,79.886505 32.6384574,79.2042485 32.0472821,78.9538177 Z",
      transform:
        "translate(30.523419, 79.570790) rotate(-11.000000) translate(-30.523419, -79.570790) ",
    },

    {
      d:
        "M22.9500809,77.0270762 L20.8092456,76.1201862 C20.2180703,75.8697555 19.5358138,76.1459835 19.285383,76.7371589 C19.0349523,77.3283342 19.3111804,78.0105907 19.9023557,78.2610215 L22.043191,79.1679114 C22.6343663,79.4183422 23.3166229,79.1421141 23.5670536,78.5509388 C23.8174843,77.9597634 23.5412563,77.2775069 22.9500809,77.0270762 Z",
      transform:
        "translate(21.426218, 77.644049) rotate(-11.000000) translate(-21.426218, -77.644049) ",
    },

    {
      d:
        "M13.8528798,75.1003346 L11.7120445,74.1934447 C11.1208692,73.9430139 10.4386126,74.219242 10.1881819,74.8104173 C9.93775115,75.4015927 10.2139792,76.0838492 10.8051546,76.3342799 L12.9459899,77.2411699 C13.5371652,77.4916006 14.2194217,77.2153726 14.4698525,76.6241972 C14.7202832,76.0330219 14.4440551,75.3507654 13.8528798,75.1003346 Z",
      transform:
        "translate(12.329017, 75.717307) rotate(-11.000000) translate(-12.329017, -75.717307) ",
    },

    {
      d:
        "M4.75567867,73.1735931 L2.61484336,72.2667031 C2.02366802,72.0162724 1.34141149,72.2925004 1.09098075,72.8836758 C0.840550013,73.4748511 1.11677808,74.1571076 1.70795342,74.4075384 L3.84878873,75.3144283 C4.43996407,75.5648591 5.12222059,75.288631 5.37265133,74.6974557 C5.62308207,74.1062803 5.346854,73.4240238 4.75567867,73.1735931 Z",
      transform:
        "translate(3.231816, 73.790566) rotate(-11.000000) translate(-3.231816, -73.790566) ",
    },

    {
      d:
        "M47.6158557,65.2403471 L46.306369,63.3191835 C45.9447642,62.7886688 45.2215585,62.6517403 44.6910438,63.013345 C44.1605291,63.3749498 44.0236006,64.0981555 44.3852054,64.6286702 L45.6946921,66.5498338 C46.0562969,67.0803485 46.7795026,67.217277 47.3100173,66.8556723 C47.8405319,66.4940675 47.9774605,65.7708618 47.6158557,65.2403471 Z",
      transform:
        "translate(46.000531, 64.934509) rotate(-11.000000) translate(-46.000531, -64.934509) ",
    },

    {
      d:
        "M41.008554,58.6970325 L39.6990673,56.7758689 C39.3374625,56.2453542 38.6142568,56.1084257 38.0837421,56.4700304 C37.5532274,56.8316352 37.4162989,57.5548409 37.7779037,58.0853556 L39.0873904,60.0065192 C39.4489952,60.5370339 40.1722009,60.6739624 40.7027156,60.3123577 C41.2332303,59.9507529 41.3701588,59.2275472 41.008554,58.6970325 Z",
      transform:
        "translate(39.393229, 58.391194) rotate(-11.000000) translate(-39.393229, -58.391194) ",
    },

    {
      d:
        "M34.4012523,52.1537179 L33.0917656,50.2325543 C32.7301608,49.7020396 32.0069551,49.5651111 31.4764404,49.9267159 C30.9459257,50.2883206 30.8089972,51.0115263 31.170602,51.542041 L32.4800887,53.4632046 C32.8416935,53.9937193 33.5648992,54.1306479 34.0954139,53.7690431 C34.6259286,53.4074383 34.7628571,52.6842326 34.4012523,52.1537179 Z",
      transform:
        "translate(32.785927, 51.847879) rotate(-11.000000) translate(-32.785927, -51.847879) ",
    },

    {
      d:
        "M27.7939506,45.6104033 L26.4844639,43.6892397 C26.1228591,43.158725 25.3996534,43.0217965 24.8691387,43.3834013 C24.338624,43.7450061 24.2016955,44.4682118 24.5633003,44.9987264 L25.872787,46.9198901 C26.2343918,47.4504047 26.9575975,47.5873333 27.4881122,47.2257285 C28.0186269,46.8641237 28.1555554,46.140918 27.7939506,45.6104033 Z",
      transform:
        "translate(26.178625, 45.304565) rotate(-11.000000) translate(-26.178625, -45.304565) ",
    },

    {
      d:
        "M21.1866489,39.0670888 L19.8771622,37.1459251 C19.5155574,36.6154105 18.7923517,36.4784819 18.261837,36.8400867 C17.7313223,37.2016915 17.5943938,37.9248972 17.9559986,38.4554118 L19.2654853,40.3765755 C19.6270901,40.9070901 20.3502958,41.0440187 20.8808105,40.6824139 C21.4113252,40.3208091 21.5482537,39.5976034 21.1866489,39.0670888 Z",
      transform:
        "translate(19.571324, 38.761250) rotate(-11.000000) translate(-19.571324, -38.761250) ",
    },

    {
      d:
        "M14.5793472,32.5237742 L13.2698605,30.6026105 C12.9082557,30.0720959 12.18505,29.9351673 11.6545353,30.2967721 C11.1240207,30.6583769 10.9870921,31.3815826 11.3486969,31.9120973 L12.6581836,33.8332609 C13.0197884,34.3637756 13.7429941,34.5007041 14.2735088,34.1390993 C14.8040235,33.7774945 14.940952,33.0542888 14.5793472,32.5237742 Z",
      transform:
        "translate(12.964022, 32.217936) rotate(-11.000000) translate(-12.964022, -32.217936) ",
    },

    {
      d:
        "M65.4844609,52.9841038 L65.2566291,50.6702935 C65.1937151,50.0313525 64.6247491,49.5643903 63.9858081,49.6273043 C63.346867,49.6902183 62.8799049,50.2591843 62.9428189,50.8981253 L63.1706507,53.2119356 C63.2335647,53.8508766 63.8025306,54.3178388 64.4414717,54.2549248 C65.0804127,54.1920108 65.5473749,53.6230448 65.4844609,52.9841038 Z",
      transform:
        "translate(64.213640, 51.941115) rotate(-11.000000) translate(-64.213640, -51.941115) ",
    },

    {
      d:
        "M62.8241802,44.0737554 L62.5963483,41.7599452 C62.5334343,41.1210041 61.9644684,40.654042 61.3255273,40.716956 C60.6865863,40.77987 60.2196241,41.3488359 60.2825381,41.987777 L60.51037,44.3015872 C60.573284,44.9405283 61.1422499,45.4074904 61.781191,45.3445764 C62.420132,45.2816624 62.8870942,44.7126965 62.8241802,44.0737554 Z",
      transform:
        "translate(61.553359, 43.030766) rotate(-11.000000) translate(-61.553359, -43.030766) ",
    },

    {
      d:
        "M60.1638994,35.1634071 L59.9360676,32.8495968 C59.8731536,32.2106558 59.3041877,31.7436936 58.6652466,31.8066076 C58.0263056,31.8695216 57.5593434,32.4384876 57.6222574,33.0774286 L57.8500892,35.3912389 C57.9130032,36.0301799 58.4819692,36.4971421 59.1209102,36.4342281 C59.7598513,36.3713141 60.2268135,35.8023481 60.1638994,35.1634071 Z",
      transform:
        "translate(58.893078, 34.120418) rotate(-11.000000) translate(-58.893078, -34.120418) ",
    },

    {
      d:
        "M57.5036187,26.2530587 L57.2757869,23.9392484 C57.2128729,23.3003074 56.6439069,22.8333452 56.0049659,22.8962592 C55.3660248,22.9591733 54.8990627,23.5281392 54.9619767,24.1670803 L55.1898085,26.4808905 C55.2527225,27.1198316 55.8216885,27.5867937 56.4606295,27.5238797 C57.0995706,27.4609657 57.5665327,26.8919997 57.5036187,26.2530587 Z",
      transform:
        "translate(56.232798, 25.210069) rotate(-11.000000) translate(-56.232798, -25.210069) ",
    },

    {
      d:
        "M54.843338,17.3427103 L54.6155062,15.0289001 C54.5525921,14.389959 53.9836262,13.9229969 53.3446852,13.9859109 C52.7057441,14.0488249 52.2387819,14.6177909 52.301696,15.2567319 L52.5295278,17.5705422 C52.5924418,18.2094832 53.1614077,18.6764454 53.8003488,18.6135313 C54.4392898,18.5506173 54.906252,17.9816514 54.843338,17.3427103 Z",
      transform:
        "translate(53.572517, 16.299721) rotate(-11.000000) translate(-53.572517, -16.299721) ",
    },

    {
      d:
        "M52.1830573,8.43236198 L51.9552254,6.11855172 C51.8923114,5.47961068 51.3233455,5.01264851 50.6844044,5.07556253 C50.0454634,5.13847655 49.5785012,5.70744249 49.6414152,6.34638354 L49.869247,8.66019379 C49.9321611,9.29913483 50.501127,9.766097 51.1400681,9.70318299 C51.7790091,9.64026897 52.2459713,9.07130302 52.1830573,8.43236198 Z",
      transform:
        "translate(50.912236, 7.389373) rotate(-11.000000) translate(-50.912236, -7.389373) ",
    },

    {
      d:
        "M89.5453499,54.014641 L91.0276626,52.2234474 C91.4369919,51.7288229 91.3678465,50.9960236 90.8732221,50.5866943 C90.3785976,50.177365 89.6457983,50.2465104 89.236469,50.7411348 L87.7541564,52.5323284 C87.3448271,53.0269529 87.4139724,53.7597522 87.9085969,54.1690815 C88.4032213,54.5784108 89.1360206,54.5092654 89.5453499,54.014641 Z",
      transform:
        "translate(89.390909, 52.377888) rotate(-11.000000) translate(-89.390909, -52.377888) ",
    },

    {
      d:
        "M93.9980811,45.8510274 L95.4803937,44.0598338 C95.889723,43.5652093 95.8205776,42.83241 95.3259532,42.4230807 C94.8313287,42.0137514 94.0985294,42.0828968 93.6892001,42.5775212 L92.2068875,44.3687148 C91.7975582,44.8633393 91.8667035,45.5961386 92.361328,46.0054679 C92.8559524,46.4147972 93.5887517,46.3456518 93.9980811,45.8510274 Z",
      transform:
        "translate(93.843641, 44.214274) rotate(-11.000000) translate(-93.843641, -44.214274) ",
    },

    {
      d:
        "M98.4508122,37.6874138 L99.9331248,35.8962202 C100.342454,35.4015957 100.273309,34.6687964 99.7786843,34.2594671 C99.2840599,33.8501378 98.5512605,33.9192832 98.1419312,34.4139076 L96.6596186,36.2051012 C96.2502893,36.6997256 96.3194347,37.432525 96.8140591,37.8418543 C97.3086835,38.2511836 98.0414829,38.1820382 98.4508122,37.6874138 Z",
      transform:
        "translate(98.296372, 36.050661) rotate(-11.000000) translate(-98.296372, -36.050661) ",
    },

    {
      d:
        "M102.903543,29.5238002 L104.385856,27.7326066 C104.795185,27.2379821 104.72604,26.5051828 104.231415,26.0958535 C103.736791,25.6865242 103.003992,25.7556696 102.594662,26.250294 L101.11235,28.0414876 C100.70302,28.536112 100.772166,29.2689114 101.26679,29.6782407 C101.761415,30.08757 102.494214,30.0184246 102.903543,29.5238002 Z",
      transform:
        "translate(102.749103, 27.887047) rotate(-11.000000) translate(-102.749103, -27.887047) ",
    },

    {
      d:
        "M107.356274,21.3601866 L108.838587,19.568993 C109.247916,19.0743685 109.178771,18.3415692 108.684147,17.9322399 C108.189522,17.5229106 107.456723,17.5920559 107.047393,18.0866804 L105.565081,19.877874 C105.155752,20.3724984 105.224897,21.1052978 105.719521,21.5146271 C106.214146,21.9239564 106.946945,21.854811 107.356274,21.3601866 Z",
      transform:
        "translate(107.201834, 19.723433) rotate(-11.000000) translate(-107.201834, -19.723433) ",
    },

    {
      d:
        "M111.809006,13.196573 L113.291318,11.4053794 C113.700647,10.9107549 113.631502,10.1779556 113.136878,9.7686263 C112.642253,9.35929698 111.909454,9.42844234 111.500125,9.92306679 L110.017812,11.7142604 C109.608483,12.2088848 109.677628,12.9416842 110.172252,13.3510135 C110.666877,13.7603428 111.399676,13.6911974 111.809006,13.196573 Z",
      transform:
        "translate(111.654565, 11.559820) rotate(-11.000000) translate(-111.654565, -11.559820) ",
    },

    {
      d:
        "M116.261737,5.03295938 L117.744049,3.24176578 C118.153379,2.74714133 118.084233,2.01434202 117.589609,1.6050127 C117.094984,1.19568338 116.362185,1.26482874 115.952856,1.75945319 L114.470543,3.55064679 C114.061214,4.04527123 114.130359,4.77807055 114.624984,5.18739987 C115.119608,5.59672919 115.852407,5.52758382 116.261737,5.03295938 Z",
      transform:
        "translate(116.107296, 3.396206) rotate(-11.000000) translate(-116.107296, -3.396206) ",
    },

    {
      d:
        "M107.976529,77.1940549 L110.238001,76.6542684 C110.862489,76.5052105 111.247902,75.8781275 111.098844,75.2536392 C110.949786,74.629151 110.322703,74.2437385 109.698215,74.3927964 L107.436743,74.9325829 C106.812254,75.0816408 106.426842,75.7087239 106.5759,76.3332121 C106.724958,76.9577004 107.352041,77.3431128 107.976529,77.1940549 Z",
      transform:
        "translate(108.837372, 75.793426) rotate(-11.000000) translate(-108.837372, -75.793426) ",
    },

    {
      d:
        "M116.443324,73.3489553 L118.704796,72.8091689 C119.329284,72.660111 119.714696,72.0330279 119.565639,71.4085397 C119.416581,70.7840514 118.789498,70.398639 118.165009,70.5476969 L115.903537,71.0874834 C115.279049,71.2365413 114.893637,71.8636243 115.042694,72.4881126 C115.191752,73.1126008 115.818835,73.4980133 116.443324,73.3489553 Z",
      transform:
        "translate(117.304167, 71.948326) rotate(-11.000000) translate(-117.304167, -71.948326) ",
    },

    {
      d:
        "M124.910118,69.5038558 L127.17159,68.9640693 C127.796079,68.8150114 128.181491,68.1879283 128.032433,67.5634401 C127.883375,66.9389518 127.256292,66.5535394 126.631804,66.7025973 L124.370332,67.2423838 C123.745844,67.3914417 123.360431,68.0185248 123.509489,68.643013 C123.658547,69.2675013 124.28563,69.6529137 124.910118,69.5038558 Z",
      transform:
        "translate(125.770961, 68.103227) rotate(-11.000000) translate(-125.770961, -68.103227) ",
    },

    {
      d:
        "M133.376913,65.6587562 L135.638385,65.1189698 C136.262873,64.9699118 136.648286,64.3428288 136.499228,63.7183405 C136.35017,63.0938523 135.723087,62.7084399 135.098599,62.8574978 L132.837126,63.3972842 C132.212638,63.5463422 131.827226,64.1734252 131.976284,64.7979135 C132.125342,65.4224017 132.752425,65.8078141 133.376913,65.6587562 Z",
      transform:
        "translate(134.237756, 64.258127) rotate(-11.000000) translate(-134.237756, -64.258127) ",
    },

    {
      d:
        "M141.843708,61.8136567 L144.10518,61.2738702 C144.729668,61.1248123 145.11508,60.4977292 144.966022,59.873241 C144.816964,59.2487527 144.189881,58.8633403 143.565393,59.0123982 L141.303921,59.5521847 C140.679433,59.7012426 140.29402,60.3283257 140.443078,60.9528139 C140.592136,61.5773022 141.219219,61.9627146 141.843708,61.8136567 Z",
      transform:
        "translate(142.704550, 60.413027) rotate(-11.000000) translate(-142.704550, -60.413027) ",
    },

    {
      d:
        "M150.310502,57.9685571 L152.571974,57.4287706 C153.196462,57.2797127 153.581875,56.6526297 153.432817,56.0281414 C153.283759,55.4036532 152.656676,55.0182407 152.032188,55.1672987 L149.770716,55.7070851 C149.146227,55.856143 148.760815,56.4832261 148.909873,57.1077144 C149.058931,57.7322026 149.686014,58.117615 150.310502,57.9685571 Z",
      transform:
        "translate(151.171345, 56.567928) rotate(-11.000000) translate(-151.171345, -56.567928) ",
    },

    {
      d:
        "M158.799759,54.1889737 L160.358358,53.8169547 C160.982846,53.6678968 161.368259,53.0408137 161.219201,52.4163255 C161.070143,51.7918372 160.44306,51.4064248 159.818572,51.5554827 L158.259973,51.9275017 C157.635485,52.0765596 157.250072,52.7036426 157.39913,53.3281309 C157.548188,53.9526191 158.175271,54.3380316 158.799759,54.1889737 Z",
      transform:
        "translate(159.309165, 52.872228) rotate(-11.000000) translate(-159.309165, -52.872228) ",
    },

    {
      d:
        "M99.5460288,98.1000884 L97.7414291,96.6341262 C97.2431027,96.229312 96.5109625,96.3051186 96.1061482,96.803445 C95.7013339,97.3017714 95.7771405,98.0339117 96.275467,98.4387259 L98.0800667,99.9046881 C98.5783931,100.309502 99.3105333,100.233696 99.7153476,99.7353693 C100.120162,99.2370429 100.044355,98.5049027 99.5460288,98.1000884 Z",
      transform:
        "translate(97.910748, 98.269407) rotate(-11.000000) translate(-97.910748, -98.269407) ",
    },

    {
      d:
        "M107.749798,102.478395 L105.945198,101.012433 C105.446872,100.607619 104.714732,100.683426 104.309917,101.181752 C103.905103,101.680078 103.98091,102.412219 104.479236,102.817033 L106.283836,104.282995 C106.782162,104.687809 107.514303,104.612003 107.919117,104.113676 C108.323931,103.61535 108.248125,102.88321 107.749798,102.478395 Z",
      transform:
        "translate(106.114517, 102.647714) rotate(-11.000000) translate(-106.114517, -102.647714) ",
    },

    {
      d:
        "M115.953567,106.856702 L114.148968,105.39074 C113.650641,104.985926 112.918501,105.061732 112.513687,105.560059 C112.108872,106.058385 112.184679,106.790526 112.683005,107.19534 L114.487605,108.661302 C114.985932,109.066116 115.718072,108.99031 116.122886,108.491983 C116.5277,107.993657 116.451894,107.261517 115.953567,106.856702 Z",
      transform:
        "translate(114.318286, 107.026021) rotate(-11.000000) translate(-114.318286, -107.026021) ",
    },

    {
      d:
        "M124.157337,111.235009 L122.352737,109.769047 C121.85441,109.364233 121.12227,109.440039 120.717456,109.938366 C120.312642,110.436692 120.388448,111.168832 120.886775,111.573647 L122.691374,113.039609 C123.189701,113.444423 123.921841,113.368617 124.326655,112.87029 C124.73147,112.371964 124.655663,111.639824 124.157337,111.235009 Z",
      transform:
        "translate(122.522056, 111.404328) rotate(-11.000000) translate(-122.522056, -111.404328) ",
    },

    {
      d:
        "M132.361106,115.613316 L130.556506,114.147354 C130.05818,113.74254 129.326039,113.818346 128.921225,114.316673 C128.516411,114.814999 128.592218,115.547139 129.090544,115.951954 L130.895144,117.417916 C131.39347,117.82273 132.12561,117.746924 132.530425,117.248597 C132.935239,116.750271 132.859432,116.01813 132.361106,115.613316 Z",
      transform:
        "translate(130.725825, 115.782635) rotate(-11.000000) translate(-130.725825, -115.782635) ",
    },

    {
      d:
        "M140.564875,119.991623 L138.760275,118.525661 C138.261949,118.120847 137.529809,118.196653 137.124994,118.69498 C136.72018,119.193306 136.795987,119.925446 137.294313,120.330261 L139.098913,121.796223 C139.597239,122.201037 140.32938,122.12523 140.734194,121.626904 C141.139008,121.128578 141.063201,120.396437 140.564875,119.991623 Z",
      transform:
        "translate(138.929594, 120.160942) rotate(-11.000000) translate(-138.929594, -120.160942) ",
    },
  ]

  let actionLinesRefs = useRef([])

  const setupFeatureTimeline = () => {
    featureTimeline.fromTo(
      actionLinesRefs.current,
      {
        scale: 0,
      },
      {
        duration: 0.08,
        stagger: 0.03,
        scale: 1,
      },
      "-=1"
    )

    // featureTimeline.play()
  }

  const clickFeature = e => {
    featureTimeline.restart()
    featureTimeline.play()
  }

  useEffect(() => {
    // gsap.registerPlugin(GSDevTools, MotionPathPlugin)
    gsap.registerPlugin(MotionPathPlugin)

    setupFeatureTimeline()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const [{ language }] = useTranslateValue()

  return (
    <Step>
      <Panel ref={triggerRef}>
        <PanelContent justifyContent={`bottom`}>
          {language === "en" && <Label>Peel Off Lid</Label>}
          {language === "fr" && <Label>Peel Off Couvercle</Label>}

          <StepWrapper>
            <PeelCup onClick={clickFeature}>
              <Img fluid={images.cup.childImageSharp.fluid} />
            </PeelCup>

            <svg
              width="225px"
              height="262px"
              viewBox="0 0 225 262"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              onClick={clickFeature}
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-26.000000, -4.000000)">
                  <g transform="translate(26.000000, 3.000000)">
                    <g
                      id="Open-graphic"
                      transform="translate(19.000000, 0.000000)"
                      fill="#004E7D"
                      fillRule="nonzero"
                    >
                      {actionLinesPaths.map((path, pathIndex) => (
                        <path
                          d={path.d}
                          transform={path.transform}
                          key={`peel-line-${pathIndex}`}
                          ref={el => {
                            actionLinesRefs.current[pathIndex] = el
                          }}
                        />
                      ))}
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </StepWrapper>
        </PanelContent>
      </Panel>
    </Step>
  )
}
